import React from "react";

const SVG: React.VFC = () => (
  <svg width="121" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" strokeWidth="1.5">
      <path
        d="M87.97 83.479h20.128v17.11"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.315 107.128a3.267 3.267 0 0 0 3.264-3.269 3.267 3.267 0 0 0-3.264-3.27 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.269Z"
        fill="#6B27DD"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.297 63.426h11.641M87.644 73.67h28.831V55.143"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.311 66.477a3.267 3.267 0 0 0 3.264-3.27 3.267 3.267 0 0 0-3.264-3.269 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.27Z"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.366 56.778a3.267 3.267 0 0 0 3.264-3.27 3.267 3.267 0 0 0-3.264-3.27 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.27Z"
        fill="#D02F90"
        stroke="#232639"
      />
      <path
        d="M19.43 37.16v28.881h20.998M42.277 75.85H7.79M42.277 85.658H13.991v8.501M4.526 79.12a3.267 3.267 0 0 0 3.263-3.27 3.267 3.267 0 0 0-3.263-3.27 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.27Z"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1 97.32a3.267 3.267 0 0 0 3.264-3.27 3.267 3.267 0 0 0-3.264-3.27 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.27Z"
        fill="#D02F90"
        stroke="#232639"
      />
      <path
        d="M19.54 37.051a3.267 3.267 0 0 0 3.263-3.269 3.267 3.267 0 0 0-3.264-3.27 3.267 3.267 0 0 0-3.264 3.27 3.267 3.267 0 0 0 3.264 3.27Z"
        fill="#6B27DD"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.003 56.015H47.717a2.696 2.696 0 0 1-2.72-2.724V30.62c0-9.372 7.616-17 16.972-17 9.356 0 16.972 7.628 16.972 17v22.67c-.218 1.525-1.415 2.724-2.938 2.724Zm-25.566-5.558h22.847V30.622c0-6.321-5.114-11.444-11.424-11.444s-11.423 5.123-11.423 11.444v19.835Z"
        fill="#6B27DD"
        stroke="#232639"
      />
      <path
        d="M85.251 98.737H38.47c-2.611 0-4.787-2.18-4.787-4.796V53.617c0-2.615 2.175-4.795 4.787-4.795H85.25c2.611 0 4.787 2.18 4.787 4.795v40.324c0 2.616-2.176 4.796-4.787 4.796Z"
        fill="#E1D4F8"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.013 55.361h45.694M84.707 92.198H39.013"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.061 66.368a6.088 6.088 0 0 0-6.092-6.103 6.088 6.088 0 0 0-6.093 6.103c0 2.398 1.306 4.36 3.264 5.45l-2.828 14.495h11.205l-2.61-14.604c1.849-.981 3.154-3.052 3.154-5.34Z"
        fill="#E1D4F8"
        stroke="#232639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.84 60.592s-6.528 4.578-.545 10.572l-1.523 15.04H56.53l2.72-14.386s-3.373-1.962-3.155-5.776c.218-6.322 6.745-5.45 6.745-5.45Z"
        fill="#D02F90"
        stroke="#232639"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
