import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Stack, Typography } from "@mui/material";

import { useLocalDeviceToken } from "~common/hooks/device-token-hooks";
import {
  useAnywhereCardApplications,
  useGetAnywhereCardForUser,
} from "~common/services/issued-cards";
import { useTracking } from "~common/tracking";
import usePaymentInstruments from "~src/hooks/services/usePaymentInstruments";
import { selectCatchCard, selectCurrentUser } from "~src/store";
import { setCatchCard } from "~src/store/slices/user-slice";
import { getApplicationData } from "~src/utils/catch-card";

import CatchCardError from "./CatchCardError";

type CatchCardConfirmationProps = {
  onNext: () => void;
};

const CatchCardConfirmation: React.VFC<CatchCardConfirmationProps> = ({
  onNext,
}) => {
  const dispatch = useDispatch();
  const { trackEvent, trackError } = useTracking();
  const [deviceToken] = useLocalDeviceToken();
  const currentUser = useSelector(selectCurrentUser.data);
  const catchCard = useSelector(selectCatchCard);
  const { mutate: anywhereCardApplications } = useAnywhereCardApplications();
  const { refetch: refetchCatchCard } = useGetAnywhereCardForUser({
    lazy: true,
  });
  const { paymentInstruments } = usePaymentInstruments();
  const paymentInstrument = paymentInstruments[0] || null;
  const [isPolling, setIsPolling] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleSubmit = async () => {
      const applicationData = getApplicationData(currentUser, paymentInstrument);

      if (applicationData) {
        try {
          const response = await anywhereCardApplications({
            ...applicationData,
            ...(deviceToken && {
              device_token: deviceToken
            })
          });

          trackEvent("Catch Card Application Submitted");

          if (response.status === "failed") {
            setHasError(true);
          } else {
            setIsPolling(true);
          }
        } catch (err) {
          trackError("Catch Card Activation", "Application", { error: err });
          setHasError(true);
        }
      }
    };

    if (
      currentUser &&
      paymentInstrument &&
      catchCard?.user_flow_status === "not_initiated"
    ) {
      void handleSubmit();
    }
  }, [
    currentUser,
    deviceToken,
    paymentInstrument,
    catchCard,
    anywhereCardApplications,
    trackEvent,
    trackError,
  ]);

  useEffect(() => {
    const handleRequest = async () => {
      let response = await refetchCatchCard();

      while (
        response?.user_flow_status !== "approved" &&
        response?.user_flow_status !== "denied" &&
        response?.user_flow_status !== "pending_manual_review"
      ) {
        /* eslint-disable no-await-in-loop */
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await refetchCatchCard();
        /* eslint-enable no-await-in-loop */
      }

      dispatch(setCatchCard(response));
      setIsPolling(false);

      if (response?.user_flow_status === "approved") {
        onNext();
      }
    };

    if (isPolling) {
      void handleRequest();
    }
  }, [isPolling, dispatch, refetchCatchCard, onNext]);

  useEffect(() => {
    if (isPolling) {
      setTimeout(() => {
        setHasTimedOut(true);
      }, 90000);
    }
  }, [isPolling]);

  if (catchCard?.user_flow_status === "pending_manual_review") {
    trackEvent("Catch Card Application Pending Manual Review");
    return <CatchCardError showIcon errorType="pending" />;
  }

  if (catchCard?.user_flow_status === "denied") {
    trackEvent("Catch Card Application Denied");
    return <CatchCardError showIcon errorType="denied" />;
  }

  if (hasError) {
    trackEvent("Catch Card Application Error");
    return <CatchCardError showIcon />;
  }

  return (
    <Stack spacing={6} color="grey.400" alignItems="center" textAlign="center">
      <CircularProgress color="inherit" size={48} />

      {hasTimedOut ? (
        <Stack alignItems="center">
          <Typography variant="h3" color="grey.700">
            Looks like it&apos;s taking longer than usual.
          </Typography>

          <Typography variant="bodyRegular" color="grey.600">
            You can navigate away and check your card status by visiting the
            account page.
          </Typography>
        </Stack>
      ) : (
        <Typography variant="bodyRegular" color="grey.600">
          Usually takes less than a minute.
        </Typography>
      )}
    </Stack>
  );
};

export default CatchCardConfirmation;
