import React, { useCallback, useEffect, useState } from "react";

import { LinearProgress } from "@mui/material";

import FullHeightDialog, {
  FullHeightDialogProps,
} from "~common/components/modals/FullHeightDialog";

import CatchCardAddressForm from "./CatchCardAddressForm";
import CatchCardConfirmation from "./CatchCardConfirmation";
import CatchCardContactForm from "./CatchCardContactForm";
import CatchCardLanding from "./CatchCardLanding";
import CatchCardLinkDebit from "./CatchCardLinkDebit";
import CatchCardPinForm from "./CatchCardPinForm";
import CatchCardPlaidIDVLink from "./CatchCardPlaidIDVLink";
import CatchCardSuccess from "./CatchCardSuccess";

type CatchCardModalProps = FullHeightDialogProps & {
  initialScreen?:
    | "landing"
    | "contact"
    | "debit"
    | "address"
    | "confirmation"
    | "success"
    | "pin";
};

const CatchCardModal: React.VFC<CatchCardModalProps> = ({
  initialScreen,
  ...props
}) => {
  const [activeScreen, setActiveScreen] = useState<keyof typeof screens>(
    initialScreen || "landing"
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showEditingCancel, setShowEditingCancel] = useState(false);

  const handleEdit = useCallback((isActive: boolean, hideCancel?: boolean) => {
    setIsEditing(isActive);
    setShowEditingCancel(isActive && !hideCancel);
  }, []);

  const screens = {
    landing: {
      heading: "Activate your Catch Card",
      component: <CatchCardLanding onNext={() => setActiveScreen("contact")} />,
      divider: undefined,
    },
    contact: {
      heading: "Contact Info",
      component: (
        <CatchCardContactForm onNext={() => setActiveScreen("debit")} />
      ),
      divider: <LinearProgress variant="determinate" value={0} />,
    },
    debit: {
      heading: "Link a Debit Card",
      component: (
        <CatchCardLinkDebit
          onNext={() => setActiveScreen("address")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
      divider: <LinearProgress variant="determinate" value={25} />,
    },
    address: {
      heading: "Billing Address",
      component: (
        <CatchCardAddressForm
          onNext={() => setActiveScreen("idv")}
          onEdit={handleEdit}
          isEditing={isEditing}
        />
      ),
      divider: <LinearProgress variant="determinate" value={50} />,
    },
    idv: {
      heading: "Identity Verification",
      component: (
        <CatchCardPlaidIDVLink onNext={() => setActiveScreen("confirmation")} />
      ),
      divider: <LinearProgress variant="determinate" value={75} />,
    },
    confirmation: {
      heading: "Confirming...",
      component: (
        <CatchCardConfirmation onNext={() => setActiveScreen("success")} />
      ),
      divider: <LinearProgress variant="determinate" value={100} />,
    },
    success: {
      heading: "Success!",
      component: <CatchCardSuccess onNext={() => setActiveScreen("pin")} />,
      divider: <LinearProgress variant="determinate" value={100} />,
    },
    pin: {
      heading: "Enter a memorable PIN",
      component: <CatchCardPinForm />,
      divider: undefined,
    },
  };

  useEffect(() => {
    if (initialScreen) {
      setActiveScreen(initialScreen);
    }
  }, [initialScreen]);

  return (
    <FullHeightDialog
      heading={screens[activeScreen]?.heading}
      divider={screens[activeScreen]?.divider}
      keepMounted
      onBack={showEditingCancel ? () => handleEdit(false) : undefined}
      {...props}
    >
      {screens[activeScreen]?.component}
    </FullHeightDialog>
  );
};

export default CatchCardModal;
