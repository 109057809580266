import React, { useEffect } from "react";

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { PrimaryButton } from "~common/components/controls/buttons";
import { EncryptionIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";
import useInitiatePlaidIDVLink from "~src/hooks/useInitiatePlaidIDVLink";

import CatchCardError from "./CatchCardError";

type CatchCardPlaidIDVLinkProps = {
  onNext: () => void;
};

const CatchCardPlaidIDVLink: React.VFC<CatchCardPlaidIDVLinkProps> = ({
  onNext,
}) => {
  const { trackEvent } = useTracking();
  const { ready, open, verifying, verified, pending, denied, error } =
    useInitiatePlaidIDVLink();

  useEffect(() => {
    if (verified) {
      onNext();
    }
  }, [verified, onNext]);

  const handleClick = () => {
    trackEvent("Plaid IDV Link Opened");
    open();
  };

  if (pending) {
    return <CatchCardError showIcon errorType="pending" />;
  }

  if (denied) {
    return <CatchCardError showIcon errorType="denied" />;
  }

  if (error) {
    return <CatchCardError showIcon />;
  }

  return (
    <Stack spacing={6}>
      <Typography>
        Almost there! We&apos;re required by law to ask you a few more ID
        verification questions.
      </Typography>

      <Box textAlign="center">
        <EncryptionIcon size="medium" />
      </Box>

      {ready &&
        <PrimaryButton
          onClick={handleClick}
          startIcon={<OpenInNewIcon />}
          loading={verifying}
        >
          Complete Verification
        </PrimaryButton>
      }
    </Stack>
  );
};

export default CatchCardPlaidIDVLink;
