import React from "react";
import { useIntercom } from "react-use-intercom";

import { Stack, StackProps, Typography } from "@mui/material";

import { DefaultButton } from "~common/components/controls/buttons";
import { AlertIcon, MeditatingCoinIcon } from "~common/components/icons/vector";
import { useTracking } from "~common/tracking";
import { APPLICATION_ERROR_MESSAGES } from "~src/utils/catch-card";

type CatchCardErrorProps = StackProps & {
  showIcon?: boolean;
  errorType?: "error" | "pending" | "denied";
};

const CatchCardError: React.VFC<CatchCardErrorProps> = ({
  showIcon,
  errorType = "error",
  ...props
}) => {
  const intercom = useIntercom();
  const { trackEvent } = useTracking();
  const errorMessage = APPLICATION_ERROR_MESSAGES[errorType];

  const handleClick = () => {
    trackEvent("Catch Card Contact us clicked");
    intercom.show();
  };

  return (
    <Stack spacing={8} color="grey.600" {...props}>
      <Stack spacing={2} alignItems="center" textAlign="center">
        {showIcon && errorType !== "pending" && <AlertIcon size="small" />}
        {showIcon && errorType === "pending" && <MeditatingCoinIcon size="small" />}

        <Typography variant="h2" component="h3">
          {errorMessage.heading}
        </Typography>

        <Stack spacing={8}>
          {errorMessage.body.map((paragraph) => (
            <Typography key={paragraph} variant="bodyRegular">
              {paragraph}
            </Typography>
          ))}
        </Stack>
      </Stack>

      {errorType === "error" && (
        <DefaultButton onClick={handleClick}>Contact us</DefaultButton>
      )}
    </Stack>
  );
};

export default CatchCardError;
