import React from "react";

const SVG: React.VFC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.4443 52.9724H69.6908V64.3705"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.8221 68.6939C71.0554 68.6939 72.0553 67.6968 72.0553 66.4667C72.0553 65.2367 71.0554 64.2395 69.8221 64.2395C68.5887 64.2395 67.5889 65.2367 67.5889 66.4667C67.5889 67.6968 68.5887 68.6939 69.8221 68.6939Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.1816 39.7405H64.3049"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.5762 46.4217H75.3399V35.2856"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.5379 41.7057C67.7712 41.7057 68.7711 40.7085 68.7711 39.4784C68.7711 38.2484 67.7712 37.2512 66.5379 37.2512C65.3045 37.2512 64.3047 38.2484 64.3047 39.4784C64.3047 40.7085 65.3045 41.7057 66.5379 41.7057Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.2088 35.2855C76.4421 35.2855 77.442 34.2883 77.442 33.0583C77.442 31.8282 76.4421 30.8311 75.2088 30.8311C73.9754 30.8311 72.9756 31.8282 72.9756 33.0583C72.9756 34.2883 73.9754 35.2855 75.2088 35.2855Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6494 22.3147V41.4426H21.4804"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2172 47.8621H6.89844"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4799 54.4136H10.9707V57.8199"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.79667 50.0899C6.03003 50.0899 7.02987 49.0928 7.02987 47.8627C7.02987 46.6327 6.03003 45.6355 4.79667 45.6355C3.56331 45.6355 2.56348 46.6327 2.56348 47.8627C2.56348 49.0928 3.56331 50.0899 4.79667 50.0899Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1023 62.2748C12.3357 62.2748 13.3355 61.2776 13.3355 60.0475C13.3355 58.8175 12.3357 57.8203 11.1023 57.8203C9.86898 57.8203 8.86914 58.8175 8.86914 60.0475C8.86914 61.2776 9.86898 62.2748 11.1023 62.2748Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.781 22.3155C16.0144 22.3155 17.0142 21.3184 17.0142 20.0883C17.0142 18.8582 16.0144 17.8611 14.781 17.8611C13.5477 17.8611 12.5479 18.8582 12.5479 20.0883C12.5479 21.3184 13.5477 22.3155 14.781 22.3155Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.9385 29.9134V20.6115C30.9385 15.502 35.1421 11.3096 40.2654 11.3096C45.3886 11.3096 49.5922 15.502 49.5922 20.6115V30.0444"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.898 63.0602H24.6332C22.9255 63.0602 21.4805 61.619 21.4805 59.9159V33.1892C21.4805 31.4861 22.9255 30.0449 24.6332 30.0449H55.7666C57.4743 30.0449 58.9193 31.4861 58.9193 33.1892V59.9159C59.0507 61.619 57.6057 63.0602 55.898 63.0602Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.337 41.5746C44.337 39.3474 42.4979 37.5132 40.2647 37.5132C38.0315 37.5132 36.1924 39.3474 36.1924 41.5746C36.1924 43.1467 37.1119 44.4569 38.4256 45.1119L36.7178 54.8069H44.2056L42.3665 45.1119C43.5488 44.4569 44.337 43.1467 44.337 41.5746Z"
      stroke="#232639"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SVG;
