import { useGet, useMutate } from "restful-react";

import { ErrorResponseData } from "./types/error-handling-types";
import { AnywhereCardApplicationRequestData } from "./types/issued-cards-types";
import {
  AnywhereIDVSessionResponseData,
  CheckoutRiskDecisionRequest,
  LatestConsumerKYCCheckResponseData,
  RiskDecisionResponse,
} from "./types/risk-types";

const useAnywhereIDVSession = () =>
  useMutate<
    AnywhereIDVSessionResponseData,
    ErrorResponseData,
    undefined,
    AnywhereCardApplicationRequestData,
    unknown
  >({
    verb: "POST",
    path: "risk-svc/decisions/anywhere_identity_verification_session",
  });

const useLatestConsumerKYCCheck = (
  options: { lazy?: boolean } = { lazy: false }
) =>
  useGet<
    LatestConsumerKYCCheckResponseData,
    ErrorResponseData,
    undefined,
    undefined
  >({
    path: "risk-svc/latest_consumer_kyc_check",
    lazy: options.lazy,
  });

const useCheckoutRiskDescision = () =>
  useMutate<
    RiskDecisionResponse,
    ErrorResponseData,
    undefined,
    CheckoutRiskDecisionRequest,
    unknown
  >({
    verb: "POST",
    path: "risk-svc/decisions/configure_checkout",
  });

export {
  useAnywhereIDVSession,
  useCheckoutRiskDescision,
  useLatestConsumerKYCCheck,
};
