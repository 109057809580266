import { useCallback, useEffect, useState } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";
import { useDispatch, useSelector } from "react-redux";

import {
  CreatePaymentInstrumentResponseData,
  useCreatePlaidPaymentInstrument,
  usePlaidLinkTokens,
} from "../../services";
import {
  selectIsReconnectingPaymentInstrument,
  selectPaymentInstrument,
  selectPlaidCustomLinkName,
} from "../../store/selectors";
import {
  resetConnectionState,
  setBankLinkingStep,
  setIsProcessingLinkUpdate,
} from "../../store/slices/bankLinking-slice";
import { paymentInstrumentsActions } from "../../store/slices/paymentInstruments-slice";
import { useTracking } from "../../tracking";
import useBankLinkCallbacks from "./useBankLinkCallbacks";

// Payment instrument ID comes from `claimedDetails` in Checkout
// and the payment instrument data in User Portal.

type UseInitiatePlaidLink = () => {
  ready: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  open: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  exit: Function;
};

// Plaid link tokens last 30 minutes, so set an
// interval for 25 minutes just in case.

const MINUTE_MS = 6e4;
const PLAID_LINK_INTERVAL_MS = 25 * MINUTE_MS;

const useInitiatePlaidLink: UseInitiatePlaidLink = () => {
  const paymentInstrument = useSelector(selectPaymentInstrument);
  const plaidCustomLinkName = useSelector(selectPlaidCustomLinkName);
  const reconnectingPaymentInstrument = useSelector(
    selectIsReconnectingPaymentInstrument
  );
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>("");
  const { mutate: plaidLinkTokens } = usePlaidLinkTokens();
  const { mutate: createPlaidPaymentInstrument } =
    useCreatePlaidPaymentInstrument();
  const { handleBankLinkingError, linkBank } = useBankLinkCallbacks({
    connector: "plaid",
  });
  const { trackEvent, trackError } = useTracking();
  const dispatch = useDispatch();

  /*
    1. Get the link token
  */
  useEffect(() => {
    const getLinkTokens = async (): Promise<void> => {
      try {
        const linkTokenData = await plaidLinkTokens({
          link_customization_name: plaidCustomLinkName || undefined,
          payment_instrument_id: reconnectingPaymentInstrument
            ? paymentInstrument?.payment_instrument_id
            : undefined,
        });
        setPlaidLinkToken(linkTokenData.link_token);
      } catch (rawError) {
        handleBankLinkingError(rawError);
      }
    };

    void getLinkTokens();

    const plaidLinkInterval = setInterval(() => {
      void getLinkTokens();
    }, PLAID_LINK_INTERVAL_MS);

    return () => {
      clearInterval(plaidLinkInterval);
    };
  }, [
    paymentInstrument,
    dispatch,
    plaidCustomLinkName,
    plaidLinkTokens,
    reconnectingPaymentInstrument,
    handleBankLinkingError,
  ]);

  /*
   2. Define the success, failure, & event callbacks
  */
  const handleOnPlaidSuccess = useCallback(
    async (plaidToken: string, plaidData: PlaidLinkOnSuccessMetadata) => {
      const createInstrument = async (): Promise<boolean> => {
        try {
          const paymentInstrumentData: CreatePaymentInstrumentResponseData =
            await createPlaidPaymentInstrument({
              public_token: plaidToken,
              account_id: plaidData.accounts[0].id,
              account_name: plaidData.accounts[0].name,
              account_type: plaidData.accounts[0].subtype,
              account_mask: plaidData.accounts[0].mask,
              institution_id: plaidData.institution?.institution_id || "",
              institution_name: plaidData.institution?.name || "",
              precedence: "default",
            });

          dispatch(
            paymentInstrumentsActions.manualSet([paymentInstrumentData])
          );
          trackEvent("Plaid Link Success", {
            bank: plaidData.institution,
          });
          trackEvent("Payment method connected successfully");
          return true;
        } catch (rawError) {
          handleBankLinkingError(rawError);
          return false;
        }
      };

      // 1. Set loading screen
      dispatch(setIsProcessingLinkUpdate(true));

      // 2. Either reconnect or create a new payment instrument
      await linkBank(reconnectingPaymentInstrument, createInstrument);
    },
    [
      dispatch,
      linkBank,
      reconnectingPaymentInstrument,
      trackEvent,
      createPlaidPaymentInstrument,
      handleBankLinkingError,
    ]
  );

  const handleOnPlaidExit = useCallback(
    (error: null | PlaidLinkError, plaidData: PlaidLinkOnExitMetadata) => {
      // Sometimes the Plaid exit callback is fired by the SDK before Plaid even
      // opens; therefore, only complete the exit actions if the link_session_id is present
      if (plaidData.link_session_id) {
        dispatch(setBankLinkingStep("ConnectBank"));
        dispatch(resetConnectionState());
        trackEvent("Plaid Link Exited", { plaidData });
      }
    },
    [dispatch, trackEvent]
  );

  const handleOnPlaidEvent = useCallback(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      trackEvent(`Plaid Link Event ${eventName}`, { metadata });
    },
    [trackEvent]
  );

  /*
   3. Initiate Plaid
  */
  const plaidConfig: PlaidLinkOptions = {
    token: plaidLinkToken,
    onSuccess: handleOnPlaidSuccess,
    onEvent: handleOnPlaidEvent,
    onExit: handleOnPlaidExit,
  };
  const { open, ready, error, exit } = usePlaidLink(plaidConfig);
  if (error) {
    trackError("useInitiatePlaidLink", "Plaid loading error", {
      plaidError: error,
    });
  }

  /*
    4. Return the open function 
  */
  return { ready, open, exit };
};

export default useInitiatePlaidLink;
