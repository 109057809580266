// GENERATED FILE. DO NOT EDIT.

import React from "react";

import MediumSVG from "../vector-source/Encryption.medium.svg";
import SmallSVG from "../vector-source/Encryption.small.svg";
import VectorIcon, { VectorIconBaseProps } from "../VectorIcon";

const EncryptionIcon: React.VFC<VectorIconBaseProps> = (props) => (
  <VectorIcon smallSvg={SmallSVG} mediumSvg={MediumSVG} {...props} />
);

export default EncryptionIcon;
