import type { PaymentInstrument, UserResponseData } from "~common/services";

import { getAddressForCatchCardApplication } from "./address";
import { getStringBirthday } from "./dayjs";

const getApplicationData = (
  currentUser: UserResponseData | null,
  paymentInstrument: PaymentInstrument | null
) => {
  const billingAddress = getAddressForCatchCardApplication(
    currentUser?.billing_address
  );
  const birthday = getStringBirthday(currentUser);

  if (!billingAddress || !birthday || !paymentInstrument) {
    return null;
  }

  return {
    first_name: currentUser?.first_name || "",
    last_name: currentUser?.last_name || "",
    email_address: currentUser?.email || "",
    date_of_birth: birthday,
    phone_number: currentUser?.phone_number || "",
    billing_address: billingAddress,
    payment_instrument_id: paymentInstrument.payment_instrument_id,
  };
};

const APPLICATION_ERROR_MESSAGES = {
  error: {
    heading: "Sorry, we weren't able to activate your Catch card.",
    body: [
      "There are a few reasons this might've happened. You can get in touch with our team for more information.",
    ],
  },
  pending: {
    heading: "Hang tight, you're in the queue!",
    body: [
      "Due to a high volume of applications, expect your card in 1-3 business days. We'll let you know as soon as it's ready.",
      "While you wait, go claim this week's drops!",
    ],
  },
  denied: {
    heading: "Sorry, your application for the Catch card is denied.",
    body: [
      "Your ID verification did not pass the required checks. Unfortunately, we're unable to provide more specifics as to why.",
      "Please note that our customer support team cannot reverse this decision.",
    ],
  },
};

export { APPLICATION_ERROR_MESSAGES, getApplicationData };
